import { FunctionComponent } from 'react'

const CheckSVG: FunctionComponent = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="habitCellIcon successColor" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
</svg>
	)
}

export default CheckSVG
