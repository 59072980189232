import { FunctionComponent } from 'react'

import styles from "../LandingPage.module.css"

const CalculateSVG: FunctionComponent = () => {
	return (
		<svg className={styles.googleIcon}  xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M10.5 14.792H18.208V13.042H10.5ZM22.125 28.625H29.958V26.875H22.125ZM22.125 24.333H29.958V22.542H22.125ZM13.458 30H15.25V26.5H18.75V24.708H15.25V21.208H13.458V24.708H9.958V26.5H13.458ZM23.542 17.625 25.958 15.208 28.417 17.625 29.708 16.375 27.25 13.917 29.708 11.5 28.417 10.208 25.958 12.625 23.542 10.208 22.25 11.5 24.708 13.917 22.25 16.375ZM8.458 34.167Q7.375 34.167 6.604 33.396Q5.833 32.625 5.833 31.542V8.458Q5.833 7.375 6.604 6.604Q7.375 5.833 8.458 5.833H31.542Q32.625 5.833 33.396 6.604Q34.167 7.375 34.167 8.458V31.542Q34.167 32.625 33.396 33.396Q32.625 34.167 31.542 34.167ZM8.458 32.083H31.542Q31.75 32.083 31.917 31.917Q32.083 31.75 32.083 31.542V8.458Q32.083 8.25 31.917 8.083Q31.75 7.917 31.542 7.917H8.458Q8.25 7.917 8.083 8.083Q7.917 8.25 7.917 8.458V31.542Q7.917 31.75 8.083 31.917Q8.25 32.083 8.458 32.083ZM7.917 32.083Q7.917 32.083 7.917 31.917Q7.917 31.75 7.917 31.542V8.458Q7.917 8.25 7.917 8.083Q7.917 7.917 7.917 7.917Q7.917 7.917 7.917 8.083Q7.917 8.25 7.917 8.458V31.542Q7.917 31.75 7.917 31.917Q7.917 32.083 7.917 32.083Z"/></svg>
	)
}

export default CalculateSVG
