import { FunctionComponent } from 'react'

import styles from "../LandingPage.module.css"

const HeartSVG: FunctionComponent = () => {
	return (
		<svg className={styles.googleIcon}  xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M20 34.958 18.042 33.208Q11.333 27.125 7.333 22.604Q3.333 18.083 3.333 13.333Q3.333 9.542 5.896 6.979Q8.458 4.417 12.208 4.417Q14.375 4.417 16.417 5.417Q18.458 6.417 20 8.5Q21.667 6.417 23.625 5.417Q25.583 4.417 27.792 4.417Q31.542 4.417 34.104 6.979Q36.667 9.542 36.667 13.333Q36.667 18.083 32.667 22.604Q28.667 27.125 21.958 33.208ZM20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25Q20 19.25 20 19.25ZM20 31.292Q26.208 25.583 30.042 21.417Q33.875 17.25 33.875 13.333Q33.875 10.667 32.146 8.938Q30.417 7.208 27.792 7.208Q25.75 7.208 23.958 8.438Q22.167 9.667 21.208 11.833H18.792Q17.833 9.667 16.042 8.438Q14.25 7.208 12.208 7.208Q9.542 7.208 7.833 8.938Q6.125 10.667 6.125 13.333Q6.125 17.25 9.958 21.417Q13.792 25.583 20 31.292Z"/></svg>
	)
}

export default HeartSVG
