import { FunctionComponent } from 'react'

import styles from "../LandingPage.module.css"

const SummarizeSVG: FunctionComponent = () => {
	return (
		<svg className={styles.googleIcon}  xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M13.125 14.417Q13.625 14.417 14.021 14.021Q14.417 13.625 14.417 13.125Q14.417 12.583 14.021 12.188Q13.625 11.792 13.125 11.792Q12.583 11.792 12.188 12.188Q11.792 12.583 11.792 13.125Q11.792 13.625 12.188 14.021Q12.583 14.417 13.125 14.417ZM13.125 21.292Q13.625 21.292 14.021 20.917Q14.417 20.542 14.417 20Q14.417 19.458 14.021 19.083Q13.625 18.708 13.125 18.708Q12.583 18.708 12.188 19.083Q11.792 19.458 11.792 20Q11.792 20.542 12.188 20.917Q12.583 21.292 13.125 21.292ZM13.125 28.208Q13.625 28.208 14.021 27.812Q14.417 27.417 14.417 26.875Q14.417 26.375 14.021 25.979Q13.625 25.583 13.125 25.583Q12.583 25.583 12.188 25.979Q11.792 26.375 11.792 26.875Q11.792 27.417 12.188 27.812Q12.583 28.208 13.125 28.208ZM8.458 34.167Q7.375 34.167 6.604 33.396Q5.833 32.625 5.833 31.542V8.458Q5.833 7.375 6.604 6.604Q7.375 5.833 8.458 5.833H26.375L34.167 13.625V31.542Q34.167 32.625 33.396 33.396Q32.625 34.167 31.542 34.167ZM8.458 32.083H31.542Q31.792 32.083 31.937 31.938Q32.083 31.792 32.083 31.542V14.833H25.167V7.917H8.458Q8.208 7.917 8.062 8.063Q7.917 8.208 7.917 8.458V31.542Q7.917 31.792 8.062 31.938Q8.208 32.083 8.458 32.083ZM7.917 7.917V14.833V7.917V14.833V31.542Q7.917 31.792 7.917 31.938Q7.917 32.083 7.917 32.083Q7.917 32.083 7.917 31.938Q7.917 31.792 7.917 31.542V8.458Q7.917 8.208 7.917 8.063Q7.917 7.917 7.917 7.917Z"/></svg>
	)
}

export default SummarizeSVG
