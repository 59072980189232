import { FunctionComponent } from 'react'

import styles from "../LandingPage.module.css"

const AddBoxSVG: FunctionComponent = () => {
	return (
		<svg className={styles.googleIcon}  xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M18.958 27.917H21.042V21.042H27.917V18.958H21.042V12.083H18.958V18.958H12.083V21.042H18.958ZM8.458 34.167Q7.375 34.167 6.604 33.396Q5.833 32.625 5.833 31.542V8.458Q5.833 7.375 6.604 6.604Q7.375 5.833 8.458 5.833H31.542Q32.625 5.833 33.396 6.604Q34.167 7.375 34.167 8.458V31.542Q34.167 32.625 33.396 33.396Q32.625 34.167 31.542 34.167ZM8.458 32.083H31.542Q31.792 32.083 31.937 31.938Q32.083 31.792 32.083 31.542V8.458Q32.083 8.208 31.937 8.063Q31.792 7.917 31.542 7.917H8.458Q8.208 7.917 8.062 8.063Q7.917 8.208 7.917 8.458V31.542Q7.917 31.792 8.062 31.938Q8.208 32.083 8.458 32.083ZM7.917 32.083Q7.917 32.083 7.917 31.938Q7.917 31.792 7.917 31.542V8.458Q7.917 8.208 7.917 8.063Q7.917 7.917 7.917 7.917Q7.917 7.917 7.917 8.063Q7.917 8.208 7.917 8.458V31.542Q7.917 31.792 7.917 31.938Q7.917 32.083 7.917 32.083Z"/></svg>
	)
}

export default AddBoxSVG
