import { FunctionComponent } from "react";

import LandingPageComponent from "../components/LandingPage/LandingPage";


const LandingPage: FunctionComponent = () => {
    return (
    <>
        <LandingPageComponent />
    </>
    )
}

export default LandingPage