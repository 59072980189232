import { FunctionComponent } from 'react'

import styles from "../LandingPage.module.css"

const SmileySVG: FunctionComponent = () => {
	return (
		<svg className={styles.googleIcon}  xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="M26 17.958Q27 17.958 27.667 17.312Q28.333 16.667 28.333 15.625Q28.333 14.625 27.667 13.958Q27 13.292 26 13.292Q25 13.292 24.333 13.958Q23.667 14.625 23.667 15.625Q23.667 16.667 24.333 17.312Q25 17.958 26 17.958ZM14 17.958Q15 17.958 15.667 17.312Q16.333 16.667 16.333 15.625Q16.333 14.625 15.667 13.958Q15 13.292 14 13.292Q13 13.292 12.333 13.958Q11.667 14.625 11.667 15.625Q11.667 16.667 12.333 17.312Q13 17.958 14 17.958ZM20 29.125Q22.833 29.125 25.125 27.604Q27.417 26.083 28.458 23.542H26.083Q25.167 25.125 23.542 26.042Q21.917 26.958 20 26.958Q18.083 26.958 16.458 26.062Q14.833 25.167 13.958 23.542H11.542Q12.625 26.083 14.896 27.604Q17.167 29.125 20 29.125ZM20 36.667Q16.542 36.667 13.521 35.354Q10.5 34.042 8.229 31.771Q5.958 29.5 4.646 26.479Q3.333 23.458 3.333 20Q3.333 16.542 4.646 13.5Q5.958 10.458 8.229 8.208Q10.5 5.958 13.521 4.646Q16.542 3.333 20 3.333Q23.458 3.333 26.5 4.646Q29.542 5.958 31.792 8.208Q34.042 10.458 35.354 13.5Q36.667 16.542 36.667 20Q36.667 23.458 35.354 26.479Q34.042 29.5 31.792 31.771Q29.542 34.042 26.5 35.354Q23.458 36.667 20 36.667ZM20 20Q20 20 20 20Q20 20 20 20Q20 20 20 20Q20 20 20 20Q20 20 20 20Q20 20 20 20Q20 20 20 20Q20 20 20 20ZM20 33.875Q25.792 33.875 29.833 29.812Q33.875 25.75 33.875 20Q33.875 14.208 29.833 10.167Q25.792 6.125 20 6.125Q14.25 6.125 10.188 10.167Q6.125 14.208 6.125 20Q6.125 25.75 10.188 29.812Q14.25 33.875 20 33.875Z"/></svg>
	)
}

export default SmileySVG
