import { FunctionComponent } from 'react'

import styles from "../LandingPage.module.css"

const GymSVG: FunctionComponent = () => {
	return (
		<svg className={styles.heroSVG} xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 1020 668.18">
			<title>healthy_habit</title>
			<path
				d="M109.75,328.95c-7.68,23.25,14.9,44.16,32.66,61A239.26,239.26,0,0,1,198.71,472c7.72,18.73,13.09,38.88,11.65,59.09-4.08,57.32-58.06,95.51-101.19,133.48C103.06,670,96.95,675.68,93.39,683c-11.43,23.49,8,50.56,29.4,65.54C199,801.86,309,786,382.92,729.55c38.28-29.24,71.18-69,117.57-82,29.66-8.27,61.14-4.35,91.83-1.8a977.73,977.73,0,0,0,138.34,1.64c16.18-1,33.1-2.56,46.65-11.45,34.31-22.48,28.52-75.78,53.28-108.48,17.8-23.5,48.21-32.56,74.68-45.55a233.64,233.64,0,0,0,97.83-90.78c21.25-36.17,32.26-77.29,43-117.83,8.1-30.46,16.23-62.36,9.37-93.13s-33.94-59.5-65.34-56.8c-24.11,2.07-43.29,21.12-66.51,27.94C868,167.69,814,110.8,756.3,116.29c-36.33,3.45-65.63,31-87.78,60s-40.9,61.65-70.15,83.49c-45.5,34-107.84,35.2-163.54,24.19s-109.14-32.68-165.46-39.9c-33.18-4.25-58.61,15.52-88.49,26.39C153.46,280.44,119.91,298.2,109.75,328.95Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
				opacity="0.1"
			/>
			<ellipse cx="137.95" cy="646.08" rx="72.74" ry="22.1" fill="#20c98b" opacity="0.1" />
			<ellipse cx="825.73" cy="363.42" rx="194.27" ry="12.89" fill="#20c98b" opacity="0.1" />
			<g opacity="0.5">
				<path
					d="M708.28,68.4h0a10.16,10.16,0,0,1,10.16,10.16v282.5a0,0,0,0,1,0,0H698.12a0,0,0,0,1,0,0V78.55A10.16,10.16,0,0,1,708.28,68.4Z"
					fill="#20c98b"
				/>
				<path
					d="M931.21,68.4h0a10.16,10.16,0,0,1,10.16,10.16v282.5a0,0,0,0,1,0,0H921a0,0,0,0,1,0,0V78.55A10.16,10.16,0,0,1,931.21,68.4Z"
					fill="#20c98b"
				/>
				<rect
					x="899.59"
					y="56.37"
					width="20.32"
					height="361.84"
					rx="10.16"
					ry="10.16"
					transform="translate(1057.04 -788.36) rotate(90)"
					fill="#20c98b"
				/>
				<rect x="676.16" y="83.22" width="15.92" height="76.32" rx="7.96" ry="7.96" fill="#20c98b" />
				<rect x="660.24" y="90.36" width="15.92" height="62.05" rx="7.96" ry="7.96" fill="#20c98b" />
				<rect
					x="1037.4"
					y="199.13"
					width="15.92"
					height="76.32"
					rx="7.96"
					ry="7.96"
					transform="translate(2000.73 358.67) rotate(-180)"
					fill="#20c98b"
				/>
				<rect
					x="1053.33"
					y="206.27"
					width="15.92"
					height="62.05"
					rx="7.96"
					ry="7.96"
					transform="translate(2032.58 358.67) rotate(180)"
					fill="#20c98b"
				/>
			</g>
			<g opacity="0.5">
				<path
					d="M799.46,186.64H841a17,17,0,0,1,17,17V275a0,0,0,0,1,0,0h-75.5a0,0,0,0,1,0,0V203.64A17,17,0,0,1,799.46,186.64Z"
					fill="#20c98b"
				/>
				<rect x="814.68" y="268.59" width="11.05" height="36.83" fill="#20c98b" />
				<rect x="792.58" y="310.02" width="11.05" height="36.83" fill="#20c98b" />
				<rect x="838.62" y="310.02" width="11.05" height="36.83" fill="#20c98b" />
				<rect x="782.46" y="302.65" width="75.5" height="10.13" fill="#20c98b" />
			</g>
			<ellipse cx="473.09" cy="577.95" rx="414.32" ry="30.38" fill="#20c98b" opacity="0.1" />
			<path
				d="M670.36,585.67s28.54,12.89,42.35,12.89c0,0,14.73,3.68,23,7.37s40.51,5.52,40.51,5.52,37.75,13.81,42.35,20.26,18.41-23,18.41-23l-7.37-18.41-139-26.7Z"
				transform="translate(-90 -115.91)"
				fill="#ffb9b9"
			/>
			<path
				d="M670.36,585.67s28.54,12.89,42.35,12.89c0,0,14.73,3.68,23,7.37s40.51,5.52,40.51,5.52,37.75,13.81,42.35,20.26,18.41-23,18.41-23l-7.37-18.41-139-26.7Z"
				transform="translate(-90 -115.91)"
				opacity="0.05"
			/>
			<path
				d="M811.23,626.19s17.49,23,10.13,36.83-4.6,13.81,0,14.73,30.38,6.45,33.15-13.81,8.29-65.37,8.29-65.37L825,594.88S835.17,628.95,811.23,626.19Z"
				transform="translate(-90 -115.91)"
				fill="#737a9d"
			/>
			<path d="M593.94,555.29l79.18,36.83s24.86-19.34,23-29.46l-92.07-22.1Z" transform="translate(-90 -115.91)" fill="#4c4c78" />
			<path d="M593.94,555.29l79.18,36.83s24.86-19.34,23-29.46l-92.07-22.1Z" transform="translate(-90 -115.91)" opacity="0.1" />
			<path
				d="M926.32,603.17,918,623.42,898.7,628a123.29,123.29,0,0,0-17.29-12.08c-10.39-6-24.06-11.87-37-10.94,0,0-51.56-8.29-54.32-12,0,0-33.15-7.37-40.51-6.45-3.55.44-11.18-2.11-18.12-4.88-7.45-3-14.11-6.17-14.11-6.17l23.94-37.75a64.34,64.34,0,0,0,8.6,3c9.15,2.68,25.62,6.16,49.41,6.16,38.67,0,92.07,39.59,92.07,39.59s.53.18,1.44.45c4,1.21,15.4,4.06,20.66-.45C919.87,581.07,926.32,603.17,926.32,603.17Z"
				transform="translate(-90 -115.91)"
				fill="#ffb9b9"
			/>
			<path
				d="M926.32,603.17,918,623.42,898.7,628a123.29,123.29,0,0,0-17.29-12.08c28.08,2.11,11.31-28.89,11.31-28.89h.06c4,1.21,15.4,4.06,20.66-.45C919.87,581.07,926.32,603.17,926.32,603.17Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path
				d="M881.52,616.7s22.27,31.23,12.9,48.82-5.86,17.58,0,18.76,38.69,8.21,42.2-17.58c2.7-19.83.33-57.89-.91-74.41a7.89,7.89,0,0,0-8.58-7.26l-32.57,3S912,620.22,881.52,616.7Z"
				transform="translate(-90 -115.91)"
				fill="#737a9d"
			/>
			<polygon
				points="262.71 531.45 263.63 576.57 273.76 583.93 308.75 583.93 315.19 574.73 315.19 531.45 305.06 522.24 271.92 522.24 262.71 531.45"
				fill="#20c98b"
			/>
			<path d="M358.23,648.28s6.45,41.43,0,45.12l-4.6-.92-.92-44.19Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
			<path
				d="M433.73,644.6l7.37-7.37h35l7.37,7.37s9.21,26.7,1.84,44.19L477,697.08H442l-8.29-7.37Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
			/>
			<polygon
				points="262.71 531.45 263.63 576.57 273.76 583.93 308.75 583.93 315.19 574.73 315.19 531.45 305.06 522.24 271.92 522.24 262.71 531.45"
				opacity="0.1"
			/>
			<path
				d="M433.73,644.6l7.37-7.37h35l7.37,7.37s9.21,26.7,1.84,44.19L477,697.08H442l-8.29-7.37Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path d="M442,646.44s7.37,32.23,0,43.27h-8.29V644.6Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
			<path
				d="M405.19,491.76s3.68,35.91,3.68,40.51-1.84,35,3.68,64.45S408.87,663,408.87,663h30.38l12.89-99.44-9.21-52.48-3.68-16.57Z"
				transform="translate(-90 -115.91)"
				fill="#ffb9b9"
			/>
			<path
				d="M405.19,491.76s3.68,35.91,3.68,40.51-1.84,35,3.68,64.45S408.87,663,408.87,663h30.38l12.89-99.44-9.21-52.48-3.68-16.57Z"
				transform="translate(-90 -115.91)"
				opacity="0.05"
			/>
			<path
				d="M399.67,400.61s-40.51.92-8.29,68.13c0,0,8.29,27.62,8.29,32.23s41.43,4.6,46,0-7.37-42.35-7.37-59.85S426.37,395.09,399.67,400.61Z"
				transform="translate(-90 -115.91)"
				fill="#cbcdda"
			/>
			<path
				d="M399.67,400.61s-40.51.92-8.29,68.13c0,0,8.29,27.62,8.29,32.23s41.43,4.6,46,0-7.37-42.35-7.37-59.85S426.37,395.09,399.67,400.61Z"
				transform="translate(-90 -115.91)"
				opacity="0.05"
			/>
			<path d="M350.87,361s35,37.75,64.45,10.13l-3.68,97.6s-47.88-58-81-53.4Z" transform="translate(-90 -115.91)" fill="#ffb9b9" />
			<path
				d="M749.85,540.84c-1.4,10.79-5.8,33.51-18.43,40.87-7.45-3-14.11-6.17-14.11-6.17l23.94-37.75A64.34,64.34,0,0,0,749.85,540.84Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path
				d="M747.7,535s-2.76,45.12-24.86,48.8L589.36,559.72a40.29,40.29,0,0,1-17.74-8c-5.39-4.24-12-10-15.91-15.28-2.12-2.88-3.4-5.63-3.19-7.9.92-10.13,41.43-34.07,27.62-76.42a25.24,25.24,0,0,1,6.48-3.72c11.53-4.79,38.07-8.31,76.38,31.34C663,479.79,733.89,536.88,747.7,535Z"
				transform="translate(-90 -115.91)"
				fill="#4c4c78"
			/>
			<polygon
				points="296.78 535.13 297.7 580.25 307.82 587.62 342.81 587.62 349.26 578.41 349.26 535.13 339.13 525.93 305.98 525.93 296.78 535.13"
				fill="#20c98b"
			/>
			<path d="M392.3,652s6.45,41.43,0,45.12l-4.6-.92L386.78,652Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
			<path
				d="M488.06,647.36l7.37-7.37h35l7.37,7.37s9.21,26.7,1.84,44.19l-8.29,8.29h-35l-8.29-7.37Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
			/>
			<path d="M496.34,649.2s7.37,32.23,0,43.27h-8.29V647.36Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
			<path
				d="M439,664.86h49.81c3.9,0,7.06,3.5,7.06,7.83h0c0,4.32-3.16,7.83-7.06,7.83H439c-4.23,0-7.51-4.09-7-8.74h0C432.41,667.83,435.43,664.86,439,664.86Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
			/>
			<path
				d="M582.89,521.22c-9.85,6.9-20.23,11.42-27.19,15.27-2.12-2.88-3.4-5.63-3.19-7.9.92-10.13,41.43-34.07,27.62-76.42a25.24,25.24,0,0,1,6.48-3.72,28.12,28.12,0,0,0,6.41-.88S601.3,508.33,582.89,521.22Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path
				d="M376.19,432.37s13.81,13.81,27.16,22.56c12.32,8.07-9.67-73.2-9.67-73.2s13.64-11,21.65-17.53A27.44,27.44,0,0,1,434.41,358c18.43,1.19,52.19,8.94,79.43,46.3,39.59,54.32,75.5,43.27,75.5,43.27s8.29,60.77-10.13,73.66-38.67,17.49-35,24.86-16.57-35.91-62.61-43.27-96.68-39.59-95.75-55.24C385.86,447.57,372.51,437,376.19,432.37Z"
				transform="translate(-90 -115.91)"
				fill="#cbcdda"
			/>
			<path
				d="M366.52,385a46.27,46.27,0,0,0-.7-7.94c-12.06-6-10.35-14.16-10.35-14.16l-20.26,54.32c7.41-1,15.56,1.08,23.74,5C369.72,413.71,366.52,399.69,366.52,385Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<circle cx="236.01" cy="268.13" r="46.04" fill="#ffb9b9" />
			<path
				d="M284.18,394.42c-.85,2.73-2.16,5.54-4.63,7-3,1.78-7.14.9-9.81-1.4s-4.11-5.74-4.86-9.18a30.82,30.82,0,0,1,.5-15.05c1.2-4.15,3.27-8,4.14-12.25.93-4.52.46-9.25,1.5-13.75,1.41-6.1,5.43-11.21,9.35-16.1a16.62,16.62,0,0,1,10.83-7.2l19.27-5.81A65.11,65.11,0,0,1,321.69,318a38.87,38.87,0,0,1,14.59,1.28A33.61,33.61,0,0,1,346.86,324a39.13,39.13,0,0,1,8.35,8.45c10.19,13.32,16,35.11,26.51,48.22-4.15,1.28-8.3-3-12.45-1.7a2.92,2.92,0,0,1-1.87.15,3.07,3.07,0,0,1-1.37-1.52,31.87,31.87,0,0,0-16.29-13.94c-5.1-1.93-12-1.92-14.79,2.76-1.89,3.16-1.13,7.15-.63,10.8s.41,8-2.48,10.25a10.72,10.72,0,0,1-5.38,1.82,59.28,59.28,0,0,1-26.66-2.61c-3-1-6.05-4.13-9.22-3.13C286.3,384.9,285.31,390.79,284.18,394.42Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path
				d="M283.17,393.41c-.85,2.73-2.16,5.54-4.63,7-3,1.78-7.14.9-9.81-1.4s-4.11-5.74-4.86-9.18a30.82,30.82,0,0,1,.5-15.05c1.2-4.15,3.27-8,4.14-12.25.93-4.52.46-9.25,1.5-13.75,1.41-6.1,5.43-11.21,9.35-16.1a16.62,16.62,0,0,1,10.83-7.2l19.27-5.81A65.11,65.11,0,0,1,320.68,317a38.87,38.87,0,0,1,14.59,1.28A33.61,33.61,0,0,1,345.85,323a39.13,39.13,0,0,1,8.35,8.45c10.19,13.32,16,35.11,26.51,48.22-4.15,1.28-8.3-3-12.45-1.7a2.92,2.92,0,0,1-1.87.15A3.07,3.07,0,0,1,365,376.6a31.87,31.87,0,0,0-16.29-13.94c-5.1-1.93-12-1.92-14.79,2.76-1.89,3.16-1.13,7.15-.63,10.8s.41,8-2.48,10.25a10.72,10.72,0,0,1-5.38,1.82,59.28,59.28,0,0,1-26.66-2.61c-3-1-6.05-4.13-9.22-3.13C285.29,383.89,284.3,389.78,283.17,393.41Z"
				transform="translate(-90 -115.91)"
				fill="#b96b6b"
			/>
			<g opacity="0.1">
				<path
					d="M396.8,379.22l-3.12,2.52s12.89,47.63,13.4,66.34C413.66,446.3,401,395.34,396.8,379.22Z"
					transform="translate(-90 -115.91)"
				/>
				<path
					d="M581,513.86c-18.41,12.89-38.67,17.49-35,24.86s-16.57-35.91-62.61-43.27c-43.19-6.91-90.43-35.7-95.35-52.16-6.9-5.9-11.92-10.91-11.92-10.91-3.68,4.6,9.67,15.19,9.67,15.19-.92,15.65,49.72,47.88,95.75,55.24s66.29,50.64,62.61,43.27,16.57-12,35-24.86c5.27-3.69,8.35-11.3,10.07-20.29C587.44,506.62,584.8,511.23,581,513.86Z"
					transform="translate(-90 -115.91)"
				/>
			</g>
			<path
				d="M488.06,602.25c-2.76,3.68-4.6,41.43-4.6,41.43s11,54.32-23,48.8-4.6-44.19-4.6-44.19l-4.6-53.4c-17.49-46-13.81-79.18-13.81-79.18l-3.27-30.07-1.34-12.28,39.59-9.21c-1.08,1.44-.62,10.42.35,20.85,1.51,16.34,4.25,36.23,4.25,36.23C485.29,530.43,490.82,598.56,488.06,602.25Z"
				transform="translate(-90 -115.91)"
				fill="#ffb9b9"
			/>
			<path
				d="M431.89,381.28s-40.51.92-8.29,68.13c0,0,8.29,27.62,8.29,32.23s41.43,4.6,46,0-7.37-42.35-7.37-59.85S458.59,375.75,431.89,381.28Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path
				d="M472.75,485c-8.6,2.35-29.68,2.56-38.61.64l-1.34-12.28,39.59-9.21C471.33,465.58,471.79,474.56,472.75,485Z"
				transform="translate(-90 -115.91)"
				opacity="0.1"
			/>
			<path
				d="M430.05,381.28s-40.51.92-8.29,68.13c0,0,8.29,27.62,8.29,32.23s41.43,4.6,46,0-7.37-42.35-7.37-59.85S456.75,375.75,430.05,381.28Z"
				transform="translate(-90 -115.91)"
				fill="#cbcdda"
			/>
			<g opacity="0.5">
				<polygon
					points="75.04 409.5 45.72 410.1 40.94 416.68 40.94 439.42 46.92 443.61 75.04 443.61 81.03 437.03 81.03 415.49 75.04 409.5"
					fill="#20c98b"
				/>
				<path d="M164.45,529s-26.93,4.19-29.32,0l.6-3,28.72-.6Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M167.44,591.23l4.79,4.79v22.74l-4.79,4.79s-17.35,6-28.72,1.2l-5.39-5.39V596.62l4.79-5.39Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<path d="M166.24,596.62s-20.94,4.79-28.12,0v-5.39h29.32Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M156.07,559.36v32.37a4.86,4.86,0,0,1-5.09,4.59h0a4.86,4.86,0,0,1-5.09-4.59V559.36c0-2.75,2.66-4.88,5.68-4.56h0A4.79,4.79,0,0,1,156.07,559.36Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<polygon
					points="122 410.42 92.68 411.02 87.89 417.6 87.89 440.34 93.88 444.53 122 444.53 127.98 437.95 127.98 416.41 122 410.42"
					fill="#20c98b"
				/>
				<path d="M211.4,529.93s-26.93,4.19-29.32,0l.6-3,28.72-.6Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M214.39,592.16l4.79,4.79v22.74l-4.79,4.79s-17.35,6-28.72,1.2l-5.39-5.39V597.54l4.79-5.39Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<path d="M213.2,597.54s-20.94,4.79-28.12,0v-5.39h29.32Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M203,560.28v32.37a4.86,4.86,0,0,1-5.09,4.59h0a4.86,4.86,0,0,1-5.09-4.59V560.28c0-2.75,2.66-4.88,5.68-4.56h0A4.79,4.79,0,0,1,203,560.28Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<polygon
					points="181.78 427.74 157.64 428.23 153.7 433.65 153.7 452.37 158.63 455.81 181.78 455.81 186.7 450.39 186.7 432.66 181.78 427.74"
					fill="#20c98b"
				/>
				<path d="M271.28,546.6s-22.17,3.45-24.14,0l.49-2.46,23.64-.49Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M273.75,597.83l3.94,3.94v18.72l-3.94,3.94s-14.28,4.93-23.64,1L245.67,621V602.26l3.94-4.43Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<path d="M272.76,602.26s-17.24,3.94-23.15,0v-4.43h24.14Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M264.39,571.59v26.65A4,4,0,0,1,260.2,602h0a4,4,0,0,1-4.19-3.78V571.59a4.05,4.05,0,0,1,4.68-3.75h0A3.94,3.94,0,0,1,264.39,571.59Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<polygon
					points="220.43 428.49 196.29 428.99 192.35 434.4 192.35 453.12 197.28 456.57 220.43 456.57 225.36 451.15 225.36 433.42 220.43 428.49"
					fill="#20c98b"
				/>
				<path d="M309.94,547.36s-22.17,3.45-24.14,0l.49-2.46,23.64-.49Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M312.4,598.59l3.94,3.94v18.72l-3.94,3.94s-14.28,4.93-23.64,1l-4.43-4.43V603l3.94-4.43Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<path d="M311.42,603s-17.24,3.94-23.15,0v-4.43H312.4Z" transform="translate(-90 -115.91)" fill="#fff" opacity="0.2" />
				<path
					d="M303,572.35V599a4,4,0,0,1-4.19,3.78h0a4,4,0,0,1-4.19-3.78V572.35a4.05,4.05,0,0,1,4.68-3.75h0A3.94,3.94,0,0,1,303,572.35Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
			</g>
			<g opacity="0.8">
				<path
					d="M207.18,705s-14.17-4.8-21,7.69a22.78,22.78,0,0,0,.3,21.93c4.48,8.1,13.71,18.61,31.2,17.68,14.84-.79,19.77-12.12,21.11-22.65a23.27,23.27,0,0,0-30.9-24.91Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<path
					d="M189.49,735.73a22.78,22.78,0,0,1-.3-21.93c6.82-12.49,21-7.69,21-7.69l.73-.26a23.06,23.06,0,0,1,16.65.37,23.06,23.06,0,0,0-19.66-1.88l-.73.26s-14.17-4.8-21,7.69a22.78,22.78,0,0,0,.3,21.93c3,5.33,8,11.7,16,15.17A35.13,35.13,0,0,1,189.49,735.73Z"
					transform="translate(-90 -115.91)"
					fill="#fff"
					opacity="0.1"
				/>
				<path d="M199.86,711s5.25,4.13,18,0" transform="translate(-90 -115.91)" opacity="0.2" />
				<path d="M198.74,681s9,24.4,8.26,30.78l7.13-.75s-12-21.39-9-30Z" transform="translate(-90 -115.91)" fill="#20c98b" />
				<path d="M198.74,681s9,24.4,8.26,30.78l7.13-.75s-12-21.39-9-30Z" transform="translate(-90 -115.91)" opacity="0.2" />
				<path
					d="M235.5,683s-14.18-7.25-20.52-1.26-7.32,21.3-7.32,21.3S230.78,706.23,235.5,683Z"
					transform="translate(-90 -115.91)"
					fill="#20c98b"
				/>
				<path
					d="M235.5,683s-14.18-7.25-20.52-1.26-7.32,21.3-7.32,21.3S230.78,706.23,235.5,683Z"
					transform="translate(-90 -115.91)"
					fill="#fff"
					opacity="0.1"
				/>
				<path
					d="M207.66,703s3.72-17.77,27.84-20"
					transform="translate(-90 -115.91)"
					fill="none"
					stroke="#6c63ff"
					strokeMiterlimit="10"
				/>
			</g>
			<path
				d="M267,727.09s-14.17-4.8-21,7.69a22.78,22.78,0,0,0,.3,21.93c4.48,8.1,13.71,18.61,31.2,17.68,14.84-.79,19.77-12.12,21.11-22.65a23.27,23.27,0,0,0-30.9-24.91Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
			/>
			<path
				d="M249.34,757.83a22.78,22.78,0,0,1-.3-21.93c6.82-12.49,21-7.69,21-7.69l.73-.26a23.06,23.06,0,0,1,16.65.37,23.06,23.06,0,0,0-19.66-1.88l-.73.26s-14.17-4.8-21,7.69a22.78,22.78,0,0,0,.3,21.93c3,5.33,8,11.7,16,15.17A35.13,35.13,0,0,1,249.34,757.83Z"
				transform="translate(-90 -115.91)"
				fill="#fff"
				opacity="0.1"
			/>
			<path d="M259.71,733.09s5.25,4.13,18,0" transform="translate(-90 -115.91)" opacity="0.2" />
			<path d="M258.58,703.07s9,24.4,8.26,30.78l7.13-.75s-12-21.39-9-30Z" transform="translate(-90 -115.91)" fill="#20c98b" />
			<path d="M258.58,703.07s9,24.4,8.26,30.78l7.13-.75s-12-21.39-9-30Z" transform="translate(-90 -115.91)" opacity="0.2" />
			<path
				d="M295.35,705.1s-14.18-7.25-20.52-1.26-7.32,21.3-7.32,21.3S290.63,728.32,295.35,705.1Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
			/>
			<path
				d="M295.35,705.1s-14.18-7.25-20.52-1.26-7.32,21.3-7.32,21.3S290.63,728.32,295.35,705.1Z"
				transform="translate(-90 -115.91)"
				fill="#fff"
				opacity="0.1"
			/>
			<path
				d="M267.51,725.14s3.72-17.77,27.84-20"
				transform="translate(-90 -115.91)"
				fill="none"
				stroke="#6c63ff"
				strokeMiterlimit="10"
			/>
			<path
				d="M153,669.17s1.39-1.55-1.22-2.17v-2.55h1.57a13.46,13.46,0,0,0-10.66-13.16v-3.09h.41v-4H133v4h.29v3.13a13.46,13.46,0,0,0-10.48,13.12h1.4v2.45s-3.32,1.57,0,4.37,2.8,4.89,2.8,4.89-1.4,5.24-4.19,5.94v48.75a10.83,10.83,0,0,0,10.83,10.83h8.56A10.83,10.83,0,0,0,153,730.86V682.1s-4.89-3.67-4-5.94A55,55,0,0,1,153,669.17Z"
				transform="translate(-90 -115.91)"
				fill="#20c98b"
			/>
		</svg>
	)
}

export default GymSVG
